const itTranslations = {
  translation: {
    title: 'Sfida del Cerchio Perfetto',
    subtitle: 'Padroneggia l\'Arte del Disegno del Cerchio! 🎯✨',
    instruction: 'Disegna un cerchio perfetto intorno al punto rosso',
    totalAttempts: 'Tentativi Totali',
    highScore: 'Miglior Punteggio',
    newRecord: 'Nuovo Record!',
    shareButton: 'Condividi Punteggio',
    shareTitle: 'Sfida del Cerchio Perfetto',
    shareText: {
      score: '🎯 Ho ottenuto {{score}}% nella Sfida del Cerchio Perfetto!',
      highScore: '🏆 Il Mio Miglior Punteggio: {{score}}%',
      attempts: '🔄 Tentativi Totali: {{count}}',
      invitation: 'Puoi battermi? Prova ora! 👇'
    },
    copied: 'Copiato negli appunti ✨',
    copyright: '© 2024 Perfect Circle Challenge. Tutti i diritti riservati.',
    seo: {
      pageTitle: 'Cerchio Perfetto - Gioco di Disegno del Cerchio',
      pageSubtitle: 'Metti alla Prova le tue Abilità di Disegno',
      metaDescription: 'Accetta la sfida di disegnare un cerchio perfetto in questo coinvolgente gioco per browser. Metti alla prova la tua precisione e competi con giocatori da tutto il mondo!',
      keywords: 'cerchio perfetto, gioco del cerchio, gioco di disegno, disegnare cerchio, gioco divertente, disegno cerchio online, sfida cerchio, abilità di disegno, gioco di precisione'
    },
    failed: 'Cerchio Incompleto'
  }
};

export default itTranslations; 