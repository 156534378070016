const ptTranslations = {
  translation: {
    title: 'O Desafio Supremo do Círculo',
    subtitle: 'Torne-se um Mestre da Arte Circular! 🎨✨',
    instruction: 'Desenhe um círculo perfeito ao redor do ponto central vermelho',
    totalAttempts: 'Total de tentativas',
    highScore: 'Melhor pontuação',
    newRecord: 'Novo recorde!',
    shareButton: 'Compartilhar pontuação',
    shareTitle: 'Desafio do Círculo Perfeito',
    shareText: {
      score: '🎯 Consegui {{score}}% de similaridade no Desafio do Círculo Perfeito!',
      highScore: '🏆 Minha melhor pontuação: {{score}}%',
      attempts: '🔄 Total de tentativas: {{count}}',
      invitation: 'Desafie-me! 👇'
    },
    copied: 'Copiado para a área de transferência ✨',
    copyright: '© 2024 Desafio do Círculo Perfeito. Todos os direitos reservados.',
    seo: {
      pageTitle: 'Desafio do Círculo Perfeito',
      pageSubtitle: 'Teste sua precisão | Jogo criativo',
      metaDescription: 'Aceite o desafio de desenhar o círculo perfeito! Obtenha pontuações precisas instantâneas com IA neste jogo criativo.',
      keywords: 'círculo perfeito,jogo de desenho,teste manual,desafio desenho,jogo criativo,teste de precisão,desenho de círculo,jogo de arte,jogo online,jogo interativo'
    }
  }
};

export default ptTranslations; 