const nlTranslations = {
  translation: {
    title: 'De Ultieme Cirkel Challenge',
    subtitle: 'Word een Cirkel Kunstenaar! 🎨✨',
    instruction: 'Teken een perfecte cirkel rond het rode middelpunt',
    totalAttempts: 'Totaal pogingen',
    highScore: 'Beste score',
    newRecord: 'Nieuw record!',
    shareButton: 'Score delen',
    shareTitle: 'Perfecte Cirkel Uitdaging',
    shareText: {
      score: '🎯 Ik behaalde {{score}}% gelijkenis in de Perfecte Cirkel Uitdaging!',
      highScore: '🏆 Mijn beste score: {{score}}%',
      attempts: '🔄 Totaal pogingen: {{count}}',
      invitation: 'Kun je mijn score verbeteren? Probeer het nu! 👇'
    },
    copied: 'Gekopieerd naar klembord ✨',
    copyright: '© 2024 Perfect Circle Challenge. Alle rechten voorbehouden.',
    seo: {
      pageTitle: 'Perfecte Cirkel - Cirkel Teken Spel',
      pageSubtitle: 'Test je Tekenvaardigheid',
      metaDescription: 'Daag jezelf uit om een perfecte cirkel te tekenen in dit verslavende browserspel. Test je precisie en wedijver met anderen wereldwijd!',
      keywords: 'perfecte cirkel,tekenspel,handtest,tekenuitdaging,creatief spel,precisietest,cirkeltekening,kunstspel,online spel,interactief spel'
    },
    failed: 'Onvolledige Cirkel'
  }
};

export default nlTranslations; 