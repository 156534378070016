const deTranslations = {
  translation: {
    title: 'Perfekter Kreis Challenge',
    subtitle: 'Meistere die Kunst des Kreiszeichnens! 🎯✨',
    instruction: 'Zeichne einen perfekten Kreis um den roten Punkt',
    totalAttempts: 'Gesamtversuche',
    highScore: 'Bestleistung',
    newRecord: 'Neuer Rekord!',
    shareButton: 'Ergebnis teilen',
    shareTitle: 'Perfekter Kreis Challenge',
    shareText: {
      score: '🎯 Ich habe {{score}}% in der Perfekter Kreis Challenge erreicht!',
      highScore: '🏆 Meine Bestleistung: {{score}}%',
      attempts: '🔄 Gesamtversuche: {{count}}',
      invitation: 'Kannst du mich übertreffen? Versuche es jetzt! 👇'
    },
    copied: 'In die Zwischenablage kopiert ✨',
    copyright: '© 2024 Perfect Circle Challenge. Alle Rechte vorbehalten.',
    seo: {
      pageTitle: 'Perfekter Kreis - Kreiszeichnen Spiel',
      pageSubtitle: 'Teste deine Kreiszeichenfähigkeiten',
      metaDescription: 'Fordere dich selbst heraus, einen perfekten Kreis in diesem fesselnden Browserspiel zu zeichnen. Teste deine Präzision und tritt gegen andere an!',
      keywords: 'perfekter kreis, kreisspiel, zeichenspiel, kreis zeichnen, lustiges spiel, online kreis zeichnen, kreis challenge, zeichenfähigkeiten, präzisionsspiel'
    },
    failed: 'Unvollständiger Kreis'
  }
};

export default deTranslations; 